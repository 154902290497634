import Button from "react-bootstrap/Button"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import FAIcon from "./UIElements/FAIcon"
import { ReactNode } from "react"

const HelpPopover: React.FC<{ children: ReactNode }> = ({ children }) => (
  <OverlayTrigger
    trigger="hover"
    placement="bottom"
    overlay={
      <Popover id="popover-basic">
        <Popover.Body>{children}</Popover.Body>
      </Popover>
    }
  >
    <Button size="sm" className="px-2 text-primary" variant="link">
      <FAIcon icon="question-circle" />
    </Button>
  </OverlayTrigger>
)

export default HelpPopover
