import React, { useContext } from "react"
import { StringsContext } from "../localization/translation"
import { getUserById, upsertUser } from "../services/user"
import { SessionContext } from "../types/Session"
import { FForm } from "../Components/Form/FormComponents"
import { Button, Card } from "react-bootstrap"
import { Constant } from "../react-declarative-state/Constant"
import { from } from "rxjs"
import SettingsPage from "../Components/PageWrappers/SettingsPage"
import { Operation } from "../react-declarative-state/Operation"
import { Async } from "../react-declarative-state/Async"

const Profile: React.FC<{}> = () => {
  const S = useContext(StringsContext)
  const session = useContext(SessionContext)

  return (
    <SettingsPage>
      <div style={{ height: "200vh" }}>
        <Card>
          <Card.Header className="py-4">
            <Card.Title className="m-0">{S.Profile}</Card.Title>
          </Card.Header>
          <Card.Body>
            <Constant value={from(getUserById(session.userId))}>
              {(user) => {
                return (
                  <Operation onDone={() => {}}>
                    {(doOperation, progress) => (
                      <FForm
                        value={user}
                        onSubmit={(updatedUser) => doOperation(upsertUser(updatedUser.companyId, updatedUser))}
                      >
                        {({ FormGroupInput }) => (
                          <>
                            <FormGroupInput type="text" title={S.Name} field="name" required />
                            <FormGroupInput type="text" title={S.Email} field="email" required />
                            <FormGroupInput type="text" title={S.Phone} field="phone" />
                            <div className="d-flex justify-content-end">
                              <Button disabled={progress === Async.Progress.Progressing} type="submit">
                                {S.Save}
                              </Button>
                            </div>
                          </>
                        )}
                      </FForm>
                    )}
                  </Operation>
                )
              }}
            </Constant>
          </Card.Body>
        </Card>
      </div>
    </SettingsPage>
  )
}

export default Profile
