import { useNavigate, useParams } from "react-router-dom"
import { FForm } from "../Components/Form/FormComponents"
import { Button, Col, Row } from "react-bootstrap"
import Bordered from "../Components/UIElements/Bordered"
import { useContext } from "react"
import { StringsContext } from "../localization/translation"
import { Operation } from "../react-declarative-state/Operation"
import { Async } from "../react-declarative-state/Async"
import { registerWithToken } from "../services/user"

interface RegisterWithTokenForm {
  name: string
  password: string
  confirmPassword: string
}

const RegisterWithToken: React.FC<{}> = () => {
  const { token } = useParams<{ token: string }>()
  const navigate = useNavigate()
  const S = useContext(StringsContext)
  const initialForm: RegisterWithTokenForm = {
    name: "",
    password: "",
    confirmPassword: "",
  }
  return (
    <Operation
      onDone={({ email }: { email: string }) => {
        const searchParams = new URLSearchParams({ email })
        navigate(`/login?${searchParams.toString()}`)
      }}
    >
      {(doOperation, progress) => (
        <FForm
          value={initialForm}
          onSubmit={(readyForm) =>
            doOperation(
              registerWithToken<{ email: string }>(token || "", {
                name: readyForm.name,
                password: readyForm.password,
              })
            )
          }
        >
          {({ FormGroupInput }, { formState }) => {
            return (
              <Row>
                <Col className="mt-5" sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
                  <Bordered className="pt-5 pb-4">
                    <>
                      <div className="text-center">
                        <h4 className="mb-3">{S.Register}</h4>
                        <p className="mb-4">{S.Register_with_token_info}</p>
                      </div>
                      <FormGroupInput required field="name" title={S.Name} type="name" />
                      <FormGroupInput required field="password" title={S.Password} type="password" />
                      <Button
                        disabled={formState.isSubmitting || progress === Async.Progress.Progressing}
                        variant="primary"
                        className="mb-3 mt-3"
                        type="submit"
                      >
                        {S.Register}
                      </Button>
                    </>
                  </Bordered>
                </Col>
              </Row>
            )
          }}
        </FForm>
      )}
    </Operation>
  )
}

export default RegisterWithToken
