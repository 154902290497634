// mFRR, aFRR, Imbalance ja FCR
import React, { useContext } from "react"
import { Constant } from "../../react-declarative-state/Constant"
import AppPageInner from "../../Components/PageWrappers/AppPageInner"
import { StringsContext } from "../../localization/translation"
import PriceForecast from "../../Components/Charts/PriceChart"
import moment from "moment"
import { Col, Row } from "react-bootstrap"
import { Variable } from "../../react-declarative-state/Variable"
import { getDashboardConfig } from "../../services/forecast"
import { SessionContext } from "../../types/Session"
import { ForecastRangeControls, ForecastView } from "./Components"

export enum Item {
  energy_prices = "energy-prices",
  capacity_prices = "capacity-prices",

  fcrn_volume = "fcrn-volume",
  ffr_price = "ffr-price",
  ffr_volume = "ffr-volume",

  mfrr_energy_price = "mfrr-energy-price",
  mfrr_energy_volume = "mfrr-energy-volume",

  mfrr_energy_up_price = "mfrr_energy_up_price",
  mfrr_energy_down_price = "mfrr_energy_down_price",

  mfrr_energy_up_volume = "mfrr_energy_up_volume",
  mfrr_energy_down_volume = "mfrr_energy_down_volume",

  fcrd_up_price = "fcrd-up-price",
  fcrd_up_volume = "fcrd-up-volume",
  fcrd_down_price = "fcrd-down-price",
  fcrd_down_volume = "fcrd-down-volume",

  afrr_capacity_up_price = "afrr-capacity-up-price",
  afrr_capacity_up_volume = "afrr-capacity-up-volume",
  afrr_capacity_down_price = "afrr-capacity-down-price",
  afrr_capacity_down_volume = "afrr-capacity-down-volume",

  mfrr_capacity_up_price = "mfrr-capacity-up-price",
  mfrr_capacity_up_volume = "mfrr-capacity-up-volume",
  mfrr_capacity_down_price = "mfrr-capacity-down-price",
  mfrr_capacity_down_volume = "mfrr-capacity-down-volume",

  afrr_energy_up_price = "afrr-energy-up-price",
  afrr_energy_up_volume = "afrr-energy-up-volume",
  afrr_energy_down_price = "afrr-energy-down-price",
  afrr_energy_down_volume = "afrr-energy-down-volume",

  imbalance_price = "imbalance-price",
}

export enum ForecastType {
  SPREAD = "SPREAD",
  FLEX = "FLEX",
  SPREAD_WITH_SPOT = "SPREAD_WITH_SPOT",
}

export enum MarketView {
  aFRR_capacity = "aFRR_capacity",
  mFRR_capacity = "mFRR_capacity",
  FCR_N = "FCR-N",
  FCR_D = "FCR-D",
  FFR = "FFR",
  mFRR_energy = "mFRR_energy",
  aFRR_energy = "aFRR_energy",
  Imbalance_price = "Imbalance_price",
}

interface AnalysisProps {
  marketView: MarketView
  dropSession: () => void
}

type Dashboard = {
  span: 6 | 12
  item: Item
  type: ForecastType
}[]

const marketViews: Record<MarketView, Dashboard> = {
  [MarketView.aFRR_capacity]: [
    { span: 6, item: Item.afrr_capacity_up_price, type: ForecastType.SPREAD },
    { span: 6, item: Item.afrr_capacity_down_price, type: ForecastType.SPREAD },
    { span: 6, item: Item.afrr_capacity_up_price, type: ForecastType.FLEX },
    { span: 6, item: Item.afrr_capacity_down_price, type: ForecastType.FLEX },
    { span: 6, item: Item.afrr_capacity_up_volume, type: ForecastType.SPREAD },
    { span: 6, item: Item.afrr_capacity_down_volume, type: ForecastType.SPREAD },
  ],
  [MarketView.mFRR_capacity]: [
    { span: 6, item: Item.mfrr_capacity_up_price, type: ForecastType.SPREAD },
    { span: 6, item: Item.mfrr_capacity_down_price, type: ForecastType.SPREAD },
    { span: 6, item: Item.mfrr_capacity_up_price, type: ForecastType.FLEX },
    { span: 6, item: Item.mfrr_capacity_down_price, type: ForecastType.FLEX },
    { span: 6, item: Item.mfrr_capacity_up_volume, type: ForecastType.SPREAD },
    { span: 6, item: Item.mfrr_capacity_down_volume, type: ForecastType.SPREAD },
  ],
  [MarketView.FCR_N]: [
    { span: 12, item: Item.energy_prices, type: ForecastType.SPREAD },
    { span: 12, item: Item.energy_prices, type: ForecastType.FLEX },
    { span: 12, item: Item.fcrn_volume, type: ForecastType.SPREAD },
  ],
  [MarketView.FCR_D]: [
    { span: 6, item: Item.fcrd_up_price, type: ForecastType.SPREAD },
    { span: 6, item: Item.fcrd_down_price, type: ForecastType.SPREAD },
    { span: 6, item: Item.fcrd_up_price, type: ForecastType.FLEX },
    { span: 6, item: Item.fcrd_down_price, type: ForecastType.FLEX },
    { span: 6, item: Item.fcrd_up_volume, type: ForecastType.SPREAD },
    { span: 6, item: Item.fcrd_down_volume, type: ForecastType.SPREAD },
  ],
  [MarketView.FFR]: [
    { span: 12, item: Item.ffr_price, type: ForecastType.SPREAD },
    { span: 12, item: Item.ffr_price, type: ForecastType.FLEX },
    { span: 12, item: Item.ffr_volume, type: ForecastType.SPREAD },
  ],
  [MarketView.mFRR_energy]: [
    { span: 12, item: Item.mfrr_energy_price, type: ForecastType.SPREAD_WITH_SPOT },
    { span: 12, item: Item.mfrr_energy_price, type: ForecastType.SPREAD },
    { span: 12, item: Item.mfrr_energy_volume, type: ForecastType.SPREAD },
  ],
  [MarketView.aFRR_energy]: [
    { span: 6, item: Item.afrr_energy_up_price, type: ForecastType.SPREAD_WITH_SPOT },
    { span: 6, item: Item.afrr_energy_down_price, type: ForecastType.SPREAD_WITH_SPOT },
    { span: 6, item: Item.afrr_energy_up_price, type: ForecastType.SPREAD },
    { span: 6, item: Item.afrr_energy_down_price, type: ForecastType.SPREAD },
    { span: 6, item: Item.afrr_energy_up_volume, type: ForecastType.SPREAD },
    { span: 6, item: Item.afrr_energy_down_volume, type: ForecastType.SPREAD },
  ],
  [MarketView.Imbalance_price]: [
    { span: 12, item: Item.imbalance_price, type: ForecastType.SPREAD_WITH_SPOT },
    { span: 12, item: Item.imbalance_price, type: ForecastType.SPREAD },
  ],
}

const Analysis: React.FC<AnalysisProps> = ({ marketView, dropSession }) => {
  const S = useContext(StringsContext)
  const session = useContext(SessionContext)
  return (
    <AppPageInner title={S.marketViewName(marketView)} dropSession={dropSession}>
      <Variable initialValue={getDashboardConfig(session.userId, session.companyId)}>
        {(config, setConfig) => {
          return (
            <>
              <Constant value={true}>
                {(active) => (
                  <Variable
                    initialValue={
                      {
                        view: active ? "live" : "two-days",
                        rangeStart: moment().startOf("hour").add(-24, "hours").toISOString(),
                        rangeEnd: moment().startOf("hour").add(24, "hours").toISOString(),
                      } as ForecastView
                    }
                  >
                    {(view, setView) => (
                      <>
                        <div className="d-flex flex-column flex-md-row justify-content-between mb-3">
                          <ForecastRangeControls strings={S} view={view} setView={setView} active={active} />
                        </div>
                        <Row>
                          {marketViews[marketView].map((forecastView) => (
                            <Col md={forecastView.span} key={forecastView.item + forecastView.type}>
                              <PriceForecast
                                span={forecastView.span}
                                start={view.rangeStart}
                                end={view.rangeEnd}
                                item={forecastView.item}
                                type={forecastView.type}
                                onDashboardConfigChanged={setConfig}
                                dashBoardConfig={"error" in config ? null : config}
                              />
                            </Col>
                          ))}
                        </Row>
                      </>
                    )}
                  </Variable>
                )}
              </Constant>
            </>
          )
        }}
      </Variable>
    </AppPageInner>
  )
}

export default Analysis
