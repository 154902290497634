import moment from "moment"
import { ButtonGroup, Button } from "react-bootstrap"
import FAIcon from "../../Components/UIElements/FAIcon"
import { Translation } from "../../localization/translation"

export interface ForecastView {
  view: "live" | "today" | "two-days" | "week" | "month"
  rangeStart: string
  rangeEnd: string
}

export function ForecastRangeControls(props: {
  strings: Translation
  active: boolean
  view: ForecastView
  setView: (forecastView: ForecastView) => void
}) {
  const { view, setView, active, strings: S } = props
  const shiftScale: Record<ForecastView["view"], { unit: "hours" | "days" | "weeks" | "months"; amount: number }> = {
    live: { unit: "hours", amount: 24 },
    today: { unit: "days", amount: 1 },
    // eslint-disable-next-line no-useless-computed-key
    ["two-days"]: { unit: "days", amount: 2 },
    week: { unit: "weeks", amount: 1 },
    month: { unit: "months", amount: 1 },
  }
  return (
    <>
      <div>
        <ButtonGroup className="me-3">
          <Button
            variant="light"
            className="border"
            onClick={() => {
              setView({
                view: view.view,
                rangeStart: moment(view.rangeStart)
                  .subtract(shiftScale[view.view].amount, shiftScale[view.view].unit)
                  .toISOString(),
                rangeEnd: moment(view.rangeEnd)
                  .subtract(shiftScale[view.view].amount, shiftScale[view.view].unit)
                  .toISOString(),
              })
            }}
          >
            <FAIcon icon="angle-left" />
          </Button>
          <Button
            variant="light"
            className="border"
            onClick={() => {
              setView({
                view: view.view,
                rangeStart: moment(view.rangeStart)
                  .add(shiftScale[view.view].amount, shiftScale[view.view].unit)
                  .toISOString(),
                rangeEnd: moment(view.rangeEnd)
                  .add(shiftScale[view.view].amount, shiftScale[view.view].unit)
                  .toISOString(),
              })
            }}
          >
            <FAIcon icon="angle-right" />
          </Button>
        </ButtonGroup>
        <ButtonGroup className="mb-3 mb-md-0">
          <Button
            className="border"
            variant="light"
            onClick={() => {
              setView({
                rangeStart: moment().startOf("hour").subtract(24, "hours").toISOString(),
                rangeEnd: moment().startOf("hour").toISOString(),
                view: "today",
              })
            }}
            active={view.view === "today"}
          >
            {S.Today}
          </Button>
          <Button
            className="d-none d-md-block border"
            variant="light"
            onClick={() => {
              setView({
                rangeStart: moment().startOf("hour").subtract(2, "days").toISOString(),
                rangeEnd: moment().startOf("hour").toISOString(),
                view: "two-days",
              })
            }}
            active={view.view === "two-days"}
          >
            {S.Two_days}
          </Button>
          <Button
            variant="light border"
            onClick={() => {
              setView({
                rangeStart: moment().startOf("hour").subtract(7, "days").toISOString(),
                rangeEnd: moment().startOf("hour").toISOString(),
                view: "week",
              })
            }}
            active={view.view === "week"}
          >
            {S.Week}
          </Button>
          <Button
            variant="light border"
            onClick={() => {
              setView({
                rangeStart: moment().startOf("hour").subtract(1, "months").toISOString(),
                rangeEnd: moment().startOf("hour").toISOString(),
                view: "month",
              })
            }}
            active={view.view === "month"}
          >
            {S.Month}
          </Button>
        </ButtonGroup>
      </div>
      <Button
        disabled={!active}
        active={view.view === "live"}
        onClick={() =>
          setView({
            rangeStart: moment().startOf("hour").add(-24, "hours").toISOString(),
            rangeEnd: moment().startOf("hour").add(24, "hours").toISOString(),
            view: "live",
          })
        }
      >
        LIVE <FAIcon className={`small ${view.view === "live" ? "animate-pulse" : ""}`} icon="circle" />
      </Button>
    </>
  )
}
