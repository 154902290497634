import { useEffect, useState } from "react"
import ProgressBar from "react-bootstrap/ProgressBar"
import { Async } from "../../react-declarative-state/Async"
import { Alert } from "react-bootstrap"
import { Translation } from "../../localization/translation"

function StupidProgressBar(props: { progress: Async.Progress; strings: Translation }) {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress === 100) {
        clearInterval(interval)
      } else {
        setProgress(progress + 10)
      }
    }, 10)
    return () => {
      clearInterval(interval)
    }
  })

  return props.progress === Async.Progress.Progressing ? (
    <ProgressBar min={0} max={100} now={progress} />
  ) : (
    <Alert variant="danger">
      <div className="d-flex flex-column">
        <div className="mb-2 text-strong">{props.strings.Something_went_wrong_title}</div>
        <div>{props.strings.Something_went_wrong_text}</div>
      </div>
    </Alert>
  )
}

export default StupidProgressBar
