import { Observable } from "rxjs"
import { Async } from "../react-declarative-state/Async"

const url = (() => {
  if (window.location.href.includes("test.forenel.com")) {
    return "https://test.forenel.com/api"
  } else if (window.location.href.includes("forenel.com")) {
    return "https://forenel.com/api"
  }
  return "http://localhost:8000"
})()

const getAuthHeader = () => ({
  authentication: `Bearer ${localStorage.getItem("sessionToken")}`,
})

export async function POST<T>(path: string, body: Object, options?: { noAuthHeader: boolean }): Promise<T> {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(url + path, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(options?.noAuthHeader ? {} : getAuthHeader()),
      },
      body: JSON.stringify(body),
    })
    if (response.status >= 200 && response.status < 300) {
      resolve(response.json() as T)
    } else {
      reject({ status: response.status, message: response.statusText })
    }
  })
}

export function OGET<T>(path: string, options?: { noAuthHeader: boolean }) {
  return Async.GET(url + path, {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...(options?.noAuthHeader ? {} : getAuthHeader()),
  }) as Observable<T>
}

export function ODELETE(path: string, options?: { noAuthHeader: boolean }) {
  return Async.DELETE(url + path, {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...(options?.noAuthHeader ? {} : getAuthHeader()),
  }) as Observable<number>
}

export function OPOST<T, K = {}>(path: string, body: T, options?: { noAuthHeader: boolean }) {
  return Async.POST(url + path, body, {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...(options?.noAuthHeader ? {} : getAuthHeader()),
  }) as Observable<K>
}

export function OPUT<T>(path: string, body: T, options?: { noAuthHeader: boolean }) {
  return Async.PUT(url + path, body, {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...(options?.noAuthHeader ? {} : getAuthHeader()),
  }) as Observable<T>
}

export async function GET<T>(path: string, options?: { noAuthHeader: boolean }): Promise<T> {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(url + path, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(options?.noAuthHeader ? {} : getAuthHeader()),
      },
    })
    if (response.status >= 200 && response.status < 300) {
      resolve(response.json() as T)
    } else {
      reject({ status: response.status, message: response.statusText })
    }
  })
}
