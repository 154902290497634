import { ForecastType, Item } from "../pages/Forecast/Forecast"
import ForecastDataType from "../pages/Forecast/ForecastDataType"
import DashboardConfig from "../types/DashboardConfig"
import { ODELETE, OGET, OPOST } from "./utility"

export function getForcast(query: { start: string; end?: string; type: ForecastType }) {
  const searchParams = new URLSearchParams(query)
  return OGET<ForecastDataType>(`/forecast/latest?${searchParams.toString()}`)
}

export function getDashboardConfig(userId: string, companyId: string) {
  return OGET<DashboardConfig | { error: "no_config" }>(`/forecast/config/company/${companyId}/user/${userId}`)
}

interface UpserDashboardConfigBody {
  item: Item
  type: ForecastType
  span?: 6 | 12
  orderNo?: number
}

export function upsertDashboardConfig(userId: string, companyId: string, body: UpserDashboardConfigBody) {
  return OPOST<UpserDashboardConfigBody, DashboardConfig>(`/forecast/config/company/${companyId}/user/${userId}`, body)
}

export function deleteFromDashboardConfig(
  userId: string,
  companyId: string,
  query: { item: Item; type: ForecastType }
) {
  const searchParams = new URLSearchParams(query)
  return ODELETE(`/forecast/config/company/${companyId}/user/${userId}?${searchParams.toString()}`)
}
