import React from "react"
import { Container } from "react-bootstrap"
import { Outlet, useNavigate } from "react-router-dom"
import FAIcon from "../UIElements/FAIcon"
import { Translation } from "../../localization/translation"

interface MobileUtilityPageWrapperProps {
  strings: Translation
  title?: string
}

const UtilityPage: React.FC<MobileUtilityPageWrapperProps> = ({ strings, title }) => {
  const navigate = useNavigate()
  const mobileHeaderHeight = 57
  const S = strings
  console.log(S)
  return (
    <div style={{ minHeight: "100%" }} className="d-flex flex-column flex-grow-1 mobile-page-fade-in">
      <div style={{ position: "fixed", width: "100%" }}>
        <div
          style={{ minHeight: mobileHeaderHeight }}
          className="bg-forenel-blue text-white mobile-page-title py-3 d-flex justify-content-center align-items-center"
        >
          <div
            className="px-3 py-3 text-white"
            style={{ position: "absolute", left: 0, cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <FAIcon icon="arrow-left" className="me-2" />
            {S.Go_back}
          </div>
          <div>{title}</div>
          <div></div>
        </div>
      </div>
      <div className="flex-grow-1 pt-3 bg-light" style={{ marginTop: mobileHeaderHeight }}>
        <Container>
          <Outlet />
        </Container>
      </div>
    </div>
  )
}

export default UtilityPage
