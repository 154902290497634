import { createHashRouter, Navigate, RouterProvider } from "react-router-dom"
import "./App.scss"
import UtilityPage from "./Components/PageWrappers/UtilityPage"
import { loadStrings, StringsContext, Translation } from "./localization/translation"
import { useEffect, useRef, useState } from "react"
import Register from "./pages/AdminRegister"
import Login from "./pages/Login"
import Session from "./types/Session"
import { checkSession } from "./services/session"
import LandingPage from "./pages/LandingPage"
import AppPage from "./Components/PageWrappers/AppPage"
import Profile from "./pages/Profile"
import Analysis, { MarketView } from "./pages/Forecast/Forecast"
import Company from "./pages/Company"
import Users from "./pages/Users"
import RegisterWithToken from "./pages/RegisterWithToken"
import ForgotPassword from "./pages/ForgotPassword"
import ResetPassword from "./pages/ResetPassword"
import AdminRegister from "./pages/AdminRegister"
import ForecastDashboard from "./pages/Forecast/ForecastDashboard"

function App() {
  const [appState, setAppState] = useState({ strings: null as null | Translation, session: null as null | Session })

  const { strings, session } = appState

  const setSession = (sessionToSet: Session | null) => {
    setAppState({ strings, session: sessionToSet })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const init = async () => {
    if (!strings) {
      const loadedStrings = await loadStrings()
      const loadedSession = await checkSession().catch(() => {
        return null
      })
      setAppState({ strings: loadedStrings, session: loadedSession })
    }
  }
  const inited = useRef(false)
  useEffect(() => {
    if (!inited.current) {
      init()
    }
    inited.current = true
  })

  if (!strings) return null

  const dropSession = () => {
    setSession(null)
    router.navigate("/")
  }

  const router = createHashRouter([
    // Pages with a navbar
    { path: "/", element: <LandingPage session={session} /> },
    // Public utility pages
    {
      path: "/",
      element: <UtilityPage title={""} strings={strings} />,
      children: [
        {
          path: "/login",
          element: (
            <Login
              onSetSession={(session) => {
                setSession(session)
                router.navigate("/app")
              }}
            />
          ),
        },
        { path: "/register", element: <Register /> },
        { path: "/register-token/:token", element: <RegisterWithToken /> },
        { path: "/forgot-password", element: <ForgotPassword /> },
        { path: "/reset-password/:token", element: <ResetPassword /> },
        { path: "/admin-register", element: <AdminRegister /> },
      ],
    },
    // App pages
    {
      path: "/app",
      element: <AppPage session={session} />,
      children: [
        { path: "/app", element: <Navigate to={`/app/dashboard`} /> },
        { path: "/app/dashboard", element: <ForecastDashboard dropSession={dropSession} /> },
        ...Object.values(MarketView).map((marketView) => ({
          path: `/app/${marketView}`,
          element: <Analysis marketView={marketView} dropSession={dropSession} />,
        })),
        { path: "/app/profile", element: <Profile /> },
        { path: "/app/company", element: <Company /> },
        { path: "/app/users", element: <Users /> },
      ],
    },
  ])
  return (
    <div style={{ height: "100vh" }}>
      <StringsContext.Provider value={strings}>
        <RouterProvider router={router} />
      </StringsContext.Provider>
    </div>
  )
}

export default App
