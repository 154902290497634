import React, { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FForm } from "../Components/Form/FormComponents"
import Bordered from "../Components/UIElements/Bordered"
import { useContext } from "react"
import { StringsContext } from "../localization/translation"
import { useNavigate, useParams } from "react-router-dom"
import { Operation } from "../react-declarative-state/Operation"
import { Async } from "../react-declarative-state/Async"
import { setPassword } from "../services/user"
import { toast } from "../portals/toast"

interface PasswordForm {
  newPassword: string
}

const ResetPassword: React.FC<{}> = () => {
  const S = useContext(StringsContext)
  const { token } = useParams<{ token: string }>()
  const navigate = useNavigate()
  const [done, setDone] = useState(false)
  return (
    <Operation
      onDone={() => {
        toast(S.Password_reset_success, "success")
        setDone(true)
      }}
    >
      {(doOperation, progress) => (
        <FForm
          value={{ newPassword: "" } as PasswordForm}
          onSubmit={async ({ newPassword }) => {
            doOperation(setPassword(token || "", newPassword))
          }}
        >
          {({ FormGroupInput }, { formState }) => (
            <Row>
              <Col className="mt-5" sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
                <Bordered className="pt-5 pb-4">
                  <div className="text-center mb-4">
                    <h4 className="mb-2">{S.Set_new_password}</h4>
                    <p>{S.New_password_info}</p>
                  </div>
                  <FormGroupInput title={S.New_password} type="password" field="newPassword" required />
                  {done ? (
                    <Button variant="success" disabled={!done} onClick={() => navigate("/login")}>
                      {S.Move_to_login}
                    </Button>
                  ) : (
                    <Button
                      disabled={formState.isSubmitting || progress === Async.Progress.Progressing}
                      variant="primary"
                      className="mb-3"
                      type="submit"
                    >
                      {S.Set_password}
                    </Button>
                  )}
                </Bordered>
              </Col>
            </Row>
          )}
        </FForm>
      )}
    </Operation>
  )
}

export default ResetPassword
