import React, { ReactNode } from "react"
import { Item, MarketView } from "../pages/Forecast/Forecast"

export interface Translation {
  Account: string
  Register: string
  Log_in: string
  Log_out: string
  Profile: string
  Settings: string
  Address: string
  PostalCode: string
  BusinessId: string
  City: string
  Company: string
  to_continue_to_Forenel: string
  Required_field: string
  Wrong_email_or_password: string
  Email_wrong_format: string
  Email: string
  Password: string
  Password_again: string
  Forgot_password: string
  Save: string
  Name: string
  Your_name: string
  Register_with_token_info: string
  Phone: string
  Forecasts: string
  marketViewName: (marketView: MarketView) => string
  itemName: (item: Item) => string
  Users: string
  User_save_success: string
  Company_save_success: string
  Save_failed: string
  Something_went_wrong_title: string
  Something_went_wrong_text: string
  Admin: string
  User: string
  Delete: string
  Invite_user: string
  Invite_user_info: string
  Send: string
  Cancel: string
  Invites_sent: string
  Sending_failed: string
  Forecast: string
  Actual: string
  Go_back: string
  Today: string
  Two_days: string
  Week: string
  Month: string
  Set_new_password: string
  New_password: string
  New_password_info: string
  Forgot_password_info: string
  Sent: string
  Password_change_link_sent: string
  Set_password: string
  Password_reset_success: string
  Move_to_login: string
  Account_created: string
  Sales_rep: string
  Register_secret: string
  First_name: string
  Last_name: string
  Create_account: string
  Company_name: string
  Register_account: string
  Back_to_dashboard: string
  Add_to_front_page: string
  My_view: string
  No_dashboard_config_title: string
  No_dashboard_config: string
  ChartHelper1: ReactNode
  ChartHelper2: ReactNode
}

export enum Lang {
  fi = "fi",
}

export const StringsContext = React.createContext<Translation>(null as any)

export const loadStrings = async (): Promise<Translation> => {
  const defaultLang = "fi"
  const UILang = localStorage.getItem("lang")
  const fileKey = !UILang || !Object.keys(Lang).includes(UILang) ? defaultLang : UILang
  const translation = await import("../localization/" + fileKey)
  return translation.default
}
