import React, { ReactElement, useContext, useState } from "react"
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from "react-bootstrap"
import { MarketView } from "../../pages/Forecast/Forecast"
import { StringsContext, Translation } from "../../localization/translation"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { SessionContext } from "../../types/Session"
import { logout } from "../../services/session"
import UserIcon from "../UIElements/UserIcon"
import FAIcon from "../UIElements/FAIcon"
import logo from "./../../svg/forenel_logo.svg"

const renderMarketViewOption = (
  marketView: MarketView,
  setShowNav: (show: boolean) => void,
  navigate: NavigateFunction,
  strings: Translation
) => (
  <Nav.Link
    key={marketView}
    active={document.location.href.includes(marketView)}
    className="px-2"
    onClick={() => {
      setShowNav(false)
      navigate(`/app/${marketView}`)
    }}
  >
    {strings.marketViewName(marketView)}
  </Nav.Link>
)

const AppPageInner: React.FC<{
  title: string
  children: ReactElement
  dropSession: () => void
  loading?: boolean
  errorMessage?: string
}> = ({ title, children, dropSession }) => {
  const S = useContext(StringsContext)
  const session = useContext(SessionContext)
  const navigate = useNavigate()
  const isMobile = window.innerWidth <= 767
  const [showNav, setShowNav] = useState(!isMobile)
  return (
    <Container fluid>
      <Row>
        <Col
          data-bs-theme="dark"
          md={3}
          lg={2}
          xs={10}
          style={{ position: isMobile ? "fixed" : undefined, zIndex: isMobile ? 999999 : undefined }}
          className="border-right shadow bg-forenel-dark text-forenel-light"
        >
          <div
            style={{ height: "100vh" }}
            className={`${
              !isMobile || showNav ? "d-flex" : "d-none"
            } flex-column justify-content-between drop-shadow bordered pb-3 sticky-top`}
          >
            <div className="py-3 px-3 d-flex justify-content-between justify-content-md-center border-bottom">
              <img height={25} src={logo} alt="Forenel logo" />
              <div
                className="d-block d-sm-none p-2"
                onClick={() => {
                  setShowNav(false)
                }}
              >
                <FAIcon icon="times" />
              </div>
            </div>
            <div className="d-block mb-4 px-2">
              <Nav variant="pills" className="flex-column">
                <Nav.Link
                  className="mb-3 px-2"
                  active={document.location.href.includes("dashboard")}
                  onClick={() => {
                    setShowNav(false)
                    navigate(`/app/dashboard`)
                  }}
                >
                  <FAIcon icon="house" />
                  <span className="ms-2">{S.My_view}</span>
                </Nav.Link>
                <Nav.Item className="mb-2 ps-1 small text-muted">Day-ahead capacity</Nav.Item>
                {[MarketView.aFRR_capacity, MarketView.mFRR_capacity].map((marketView) =>
                  renderMarketViewOption(marketView, setShowNav, navigate, S)
                )}
                <Nav.Item className="mb-2 mt-4 ps-1 small text-muted">Frequency controlled</Nav.Item>
                {[MarketView.FCR_N, MarketView.FCR_D, MarketView.FFR].map((marketView) =>
                  renderMarketViewOption(marketView, setShowNav, navigate, S)
                )}
                <Nav.Item className="mb-2 mt-4 ps-1 small text-muted">Realtime energy</Nav.Item>
                {[MarketView.mFRR_energy, MarketView.aFRR_energy, MarketView.Imbalance_price].map((marketView) =>
                  renderMarketViewOption(marketView, setShowNav, navigate, S)
                )}
              </Nav>
            </div>
            <div className="border-top px-3">
              <Dropdown data-bs-theme="dark">
                <div className="d-flex">
                  <DropdownToggle
                    as="div"
                    style={{ cursor: "pointer" }}
                    className="d-flex flex-grow-1 align-items-center p-3"
                  >
                    <UserIcon initials={session.name[0]} />
                    {session.name.split(" ")[0]}
                  </DropdownToggle>
                </div>
                <DropdownMenu className="dropdown-menu-end bg-forenel-dark">
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/app/profile")
                    }}
                  >
                    {S.Profile}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    onClick={async () => {
                      await logout()
                      dropSession()
                    }}
                  >
                    {S.Log_out}
                  </NavDropdown.Item>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </Col>
        <Navbar expand="md" fixed="top" className="d-block d-sm-none justify-content-end px-3">
          <div className="d-flex justify-content-end">
            <Button
              className="bg-white shadow-sm"
              variant="link"
              onClick={() => {
                setShowNav(!showNav)
              }}
            >
              <FAIcon icon="bars" />
            </Button>
          </div>
        </Navbar>
        <Col md={9} lg={10} xs={12}>
          <Container fluid>
            <div className="p-2 px-4 my-3 border-gray rounded-pill mb-4 bg-light">
              <h4 className="m-0">{title}</h4>
            </div>
            {children}
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default AppPageInner
