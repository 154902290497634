import { Toast, ToastProps } from "react-bootstrap"
import ReactDOM from "react-dom"

const ToastElement: React.FC<{ text: string; variant: ToastProps["bg"] }> = ({ text, variant }) => (
  <Toast className="ms-auto me-auto me-md-2 mt-2" bg={variant}>
    <Toast.Body>{text}</Toast.Body>
  </Toast>
)

export async function toast(text: string, variant: ToastProps["bg"]) {
  const root = document.getElementById("toast-root")!
  const el = document.createElement("div")
  root.appendChild(el)
  ReactDOM.render(<ToastElement text={text} variant={variant || "Light"} />, el)
  setTimeout(() => {
    ReactDOM.unmountComponentAtNode(el)
    root.removeChild(el)
  }, 1500)
}
