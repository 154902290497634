import { ReactNode } from "react"

type Elements = ReactNode | ReactNode[]

const Bordered: React.FC<{ children: Elements; className?: string }> = ({ children, className }) => {
  return (
    <div className={`border rounded d-flex flex-column px-4 pb-4 pt-3 border-forenel-neutral ${className}`}>
      {children}
    </div>
  )
}

export const BorderdFooter: React.FC<{ children: Elements }> = ({ children }) => (
  <div className="d-flex justify-content-end">{children}</div>
)

export default Bordered
