import { ReactElement, useContext } from "react"
import {
  Button,
  Card,
  Col,
  Container,
  DropdownButton,
  DropdownItem,
  Nav,
  Navbar,
  NavbarCollapse,
  NavDropdown,
  NavLink,
  Row,
} from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { StringsContext } from "../../localization/translation"
import { Constant } from "../../react-declarative-state/Constant"
import { getCompanyById } from "../../services/company"
import { SessionContext } from "../../types/Session"
import FAIcon from "../UIElements/FAIcon"

const SettingsPage: React.FC<{
  children: ReactElement
}> = ({ children }) => {
  const navigate = useNavigate()
  const session = useContext(SessionContext)
  const S = useContext(StringsContext)
  return (
    <>
      <Navbar fixed="top" expand="md" className="justify-content-between px-3 bg-forenel-blue text-white border-bottom">
        <NavLink
          className="py-2"
          onClick={() => {
            navigate("/app")
          }}
        >
          <FAIcon icon="angle-left" /> {S.Back_to_dashboard}
        </NavLink>
        <Navbar.Toggle />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto d-block d-md-none">
            <Nav.Link
              onClick={() => {
                navigate("/app/profile")
              }}
            >
              {S.Profile}
            </Nav.Link>

            <Nav.Link
              onClick={() => {
                navigate("/app/company")
              }}
            >
              {S.Company}
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                navigate("/app/users")
              }}
            >
              {S.Users}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Constant value={getCompanyById(session.companyId)}>
        {({ name }) => (
          <Container fluid style={{ paddingTop: 60 }}>
            <Row>
              <Col md={3} lg={3} className="pt-3 d-none d-md-block">
                <Card className="sticky-top" style={{ top: 70 }}>
                  <Card.Header className="d-flex justify-content-center align-items-center py-4">
                    <Card.Title className="mb-0">{name}</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item className="mb-2">{S.Profile}</Nav.Item>
                      <Nav.Link
                        className="mb-2"
                        active={window.location.href.includes("profile")}
                        onClick={() => navigate("/app/profile")}
                      >
                        {session.name}
                      </Nav.Link>
                      <Nav.Item className="mb-2">{S.Account}</Nav.Item>
                      <Nav.Link
                        className="mb-2"
                        active={window.location.href.includes("company")}
                        onClick={() => navigate("/app/company")}
                      >
                        {S.Company}
                      </Nav.Link>
                      <Nav.Link
                        className="mb-2"
                        active={window.location.href.includes("users")}
                        onClick={() => navigate("/app/users")}
                      >
                        {S.Users}
                      </Nav.Link>
                    </Nav>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={9} lg={9}>
                <Container className="pt-3">{children}</Container>
              </Col>
            </Row>
          </Container>
        )}
      </Constant>
    </>
  )
}

export default SettingsPage
