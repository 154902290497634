import { FForm } from "../Components/Form/FormComponents"
import { Button, Col, Row } from "react-bootstrap"
import { Operation } from "../react-declarative-state/Operation"
import { StringsContext } from "../localization/translation"
import { useContext, useState } from "react"
import Bordered, { BorderdFooter } from "../Components/UIElements/Bordered"
import { toast } from "../portals/toast"
import { Async } from "../react-declarative-state/Async"
import { adminRegisterUser, AdminRegisterUserBody } from "../services/user"

const AdminRegister: React.FC<{}> = () => {
  const S = useContext(StringsContext)
  const [done, setDone] = useState(false)
  const defaultUser: AdminRegisterUserBody = {
    registeredBy: "",
    registerSecret: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    companyBusinessId: "",
  }
  return (
    <Operation
      onDone={() => {
        toast(S.Account_created, "success")
        setDone(true)
      }}
    >
      {(doOperation, progress) => (
        <FForm
          value={defaultUser}
          onSubmit={(newUser) => {
            doOperation(adminRegisterUser(newUser))
          }}
        >
          {({ FormGroupInput }, { formState }) => {
            return (
              <Row>
                <Col className="mt-3 mb-5" sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
                  <Bordered className="pt-5 pb-4">
                    <div className="text-center mb-4">
                      <h4>{S.Register_account}</h4>
                    </div>
                    <FormGroupInput required field="registeredBy" title={S.Sales_rep} />
                    <FormGroupInput required field="registerSecret" type="password" title={S.Register_secret} />
                    <div className="mb-3 mt-3 border-bottom"></div>
                    <FormGroupInput required field="companyName" title={S.Company} />
                    <FormGroupInput required field="companyBusinessId" title={S.BusinessId} />
                    <div className="mb-3 mt-3 border-bottom"></div>
                    <FormGroupInput required field="firstName" title={S.First_name} />
                    <FormGroupInput required field="lastName" title={S.Last_name} />
                    <FormGroupInput required field="email" type="email" title={S.Email} />
                    <FormGroupInput field="phone" type="phone" title={S.Phone} />
                    <div className="mb-3"></div>
                    <BorderdFooter>
                      <Button
                        type="submit"
                        variant={done ? "success" : "primary"}
                        disabled={done || progress === Async.Progress.Progressing || formState.isSubmitting}
                      >
                        {done ? S.Account_created : S.Create_account}
                      </Button>
                    </BorderdFooter>
                  </Bordered>
                </Col>
              </Row>
            )
          }}
        </FForm>
      )}
    </Operation>
  )
}

export default AdminRegister
