import Company from "../types/Company"
import { OGET, OPUT } from "./utility"

export const getCompanyById = (companyId: string) => {
  return OGET<Company>(`/company/${companyId}`)
}

export const editCompany = (companyId: string, company: Partial<Company>) => {
  return OPUT(`/company/${companyId}`, company)
}
