import React from "react"
import { Alert, Button, Card, Col, Row } from "react-bootstrap"
import { FForm } from "../Components/Form/FormComponents"
import { useContext, useState } from "react"
import { StringsContext } from "../localization/translation"
import { login } from "../services/session"
import Session from "../types/Session"
import { useNavigate, useSearchParams } from "react-router-dom"

interface LoginForm {
  email: string
  password: string
}

const Login: React.FC<{ onSetSession: (session: Session) => void }> = ({ onSetSession }) => {
  const S = useContext(StringsContext)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const newlyRegisteredEmail = searchParams.get("email")
  const [status, setStatus] = useState(null as null | "error" | "credential_error")
  return (
    <FForm
      value={{ email: newlyRegisteredEmail || "", password: "" } as LoginForm}
      onSubmit={async ({ email, password }) => {
        const session = await login(email, password).catch((err) => {
          if (err.status === 403) {
            setStatus("credential_error")
          } else {
            setStatus("error")
          }
          return null
        })
        if (session) {
          onSetSession(session)
        }
      }}
    >
      {({ FormGroupInput }, { formState }) => (
        <Row>
          <Col className="mt-5" sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
            <Card className="shadow-sm">
              <Card.Body className="p-4 py-5">
                <div className="text-center mb-4">
                  <h4 className="mb-2">{S.Log_in}</h4>
                  <p>{S.to_continue_to_Forenel}</p>
                </div>
                {status === "credential_error" && <Alert variant="danger">{S.Wrong_email_or_password}</Alert>}
                <FormGroupInput title={S.Email} type="email" field="email" required />
                <FormGroupInput title={S.Password} type="password" field="password" required />
                <div className="d-flex justify-content-end">
                  <Button
                    variant="link"
                    className="px-0 mb-3"
                    onClick={() => {
                      navigate("/forgot-password")
                    }}
                  >
                    {S.Forgot_password}?
                  </Button>
                </div>
                <Button disabled={formState.isSubmitting} variant="primary" className="mb-3 form-control" type="submit">
                  {S.Log_in}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </FForm>
  )
}

export default Login
