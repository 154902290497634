import { useContext } from "react"
import { StringsContext } from "../../localization/translation"
import AppPageInner from "../../Components/PageWrappers/AppPageInner"
import { getDashboardConfig } from "../../services/forecast"
import { SessionContext } from "../../types/Session"
import { Col, Row } from "react-bootstrap"
import PriceForecast from "../../Components/Charts/PriceChart"
import { Variable } from "../../react-declarative-state/Variable"
import { ForecastView } from "./Components"
import moment from "moment"

const ForecastDashboard: React.FC<{ dropSession: () => void }> = ({ dropSession }) => {
  const session = useContext(SessionContext)
  const S = useContext(StringsContext)
  return (
    <AppPageInner title={S.My_view} dropSession={dropSession}>
      <Variable
        initialValue={
          {
            view: "live",
            rangeStart: moment().startOf("hour").add(-24, "hours").toISOString(),
            rangeEnd: moment().startOf("hour").add(24, "hours").toISOString(),
          } as ForecastView
        }
      >
        {(view, setView) => (
          <Variable initialValue={getDashboardConfig(session.userId, session.companyId)}>
            {(configOrError, setConfig) => {
              if ("error" in configOrError) {
                return (
                  <Row>
                    <Col lg={{ span: 8, offset: 2 }}>
                      <div className="rounded bg-light border shadow-sm rounded p-5 d-flex flex-column">
                        <div className="mb-3">
                          <h4>{S.No_dashboard_config_title}</h4>
                        </div>
                        <div>{S.No_dashboard_config}</div>
                      </div>
                    </Col>
                  </Row>
                )
              } else {
                return (
                  <Row>
                    {configOrError.config.items.map(({ item, type, span }) => (
                      <Col md={span} key={item + type}>
                        <PriceForecast
                          span={span}
                          start={view.rangeStart}
                          end={view.rangeEnd}
                          dashBoardConfig={configOrError}
                          onDashboardConfigChanged={setConfig}
                          item={item}
                          type={type}
                        />
                      </Col>
                    ))}
                  </Row>
                )
              }
            }}
          </Variable>
        )}
      </Variable>
    </AppPageInner>
  )
}

export default ForecastDashboard
