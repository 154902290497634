import { Navigate, Outlet } from "react-router-dom"
import Session, { SessionContext } from "../../types/Session"

const AppPage: React.FC<{ session: Session | null }> = ({ session }) => {
  if (!session) {
    return <Navigate to="/" replace={true} />
  }

  return (
    <SessionContext.Provider value={session}>
      <Outlet />
    </SessionContext.Provider>
  )
}

export default AppPage
