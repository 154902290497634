import { User } from "../types/User"
import { OGET, OPOST } from "./utility"

export function getUserById(id: string) {
  return OGET<User>(`/users/${id}`)
}

export function getUsersByCompanyId(companyId: string) {
  return OGET<User[]>(`/users/company/${companyId}`)
}

export function upsertUser(companyId: string, user: Partial<User>) {
  return OPOST(`/users/company/${companyId}`, user)
}

export function inviteUsers(companyId: string, emails: string) {
  return OPOST(`/users/invite/${companyId}`, { emails })
}

export function registerWithToken<T>(token: string, credentials: { name: string; password: string }) {
  return OPOST<typeof credentials, T>(`/register-invited/${token}`, credentials)
}

export function requestPasswordReset(email: string) {
  return OPOST(`/request-password-reset`, { email })
}

export function setPassword(token: string, password: string) {
  return OPOST(`/reset-password/${token}`, { password })
}

export interface AdminRegisterUserBody {
  registeredBy: string
  registerSecret: string
  firstName: string
  lastName: string
  email: string
  phone: string
  companyName: string
  companyBusinessId: string
}

export function adminRegisterUser(body: AdminRegisterUserBody) {
  return OPOST(`/register`, body)
}
