import { FormControl, FormControlProps, FormGroup, FormLabel } from "react-bootstrap"
import { Control, Controller, FieldError, FieldValues, FormState, Path, useForm } from "react-hook-form"
import { emailRegex } from "./formHelper"

function getPattern(type: string | undefined) {
  return type
    ? {
        email: emailRegex,
      }[type]
    : undefined
}

export const FormGroupInput: React.FC<
  {
    title: string
    field: string
    control: Control<any, any>
    fieldError?: FieldError
  } & FormControlProps
> = (props) => {
  const { title, fieldError, field, control, ...restProps } = props
  return (
    <FormGroup className="mb-2">
      <FormLabel>{props.title}</FormLabel>
      <Controller
        rules={{ pattern: getPattern(props.type), required: props.required, min: restProps.min, max: restProps.max }}
        control={control}
        name={field}
        render={({ field: { onChange, value, ref }, fieldState }) => (
          <>
            <FormControl
              required={restProps.required}
              type={restProps.type}
              min={restProps.min}
              max={restProps.max}
              onChange={onChange}
              value={value || ""}
              ref={ref}
              placeholder=""
            />
            {fieldState.error?.type === "required" && <small className="text-danger">Tämä kenttä on pakollinen</small>}
            {fieldState.error?.type === "pattern" && props.type === "email" && (
              <small className="text-danger">Anna sähkäposti oikeassa muodossa</small>
            )}
          </>
        )}
      />
    </FormGroup>
  )
}

interface FFormProps<T extends FieldValues> {
  value: T
  onSubmit: (value: T) => void | Promise<void>
  children: (
    props: {
      FormGroupInput: (
        props: {
          title: string
          field: Path<T>
        } & FormControlProps
      ) => JSX.Element
    },
    state: { formState: FormState<T> }
  ) => JSX.Element
}

const createFormGroupInput = <T extends FieldValues>(control: Control<T, any>, formValue: T) => {
  return (props: { title: string; field: Path<T> } & FormControlProps) => {
    const { title, field, ...restProps } = props
    return (
      <FormGroup className="mb-3">
        <FormLabel>
          {props.title}
          {props.required && "*"}
        </FormLabel>
        <Controller
          rules={{ pattern: getPattern(props.type), required: props.required, min: restProps.min, max: restProps.max }}
          control={control}
          name={field}
          render={({ field: { onChange, value, ref }, fieldState, formState }) => (
            <>
              <FormControl
                disabled={formState.isSubmitting}
                required={restProps.required}
                type={restProps.type}
                min={restProps.min}
                max={restProps.max}
                onChange={onChange}
                value={value || ""}
                ref={ref}
                placeholder=""
              />
              {fieldState.error?.type === "required" && (
                <small className="text-danger">Tämä kenttä on pakollinen</small>
              )}
              {fieldState.error?.type === "pattern" && props.type === "email" && (
                <small className="text-danger">Anna sähkäposti oikeassa muodossa</small>
              )}
            </>
          )}
        />
      </FormGroup>
    )
  }
}

export const FForm = <T extends FieldValues>(props: FFormProps<T>) => {
  const { control, handleSubmit, formState } = useForm<T>({ mode: "onSubmit", values: props.value })
  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      {props.children({ FormGroupInput: createFormGroupInput(control, props.value) }, { formState })}
    </form>
  )
}
