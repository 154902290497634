import Session from "../types/Session"
import { User } from "../types/User"
import { GET, POST } from "./utility"

export async function login(email: User["email"], password: string) {
  const session = await POST<Session>("/login", { email, password })
  localStorage.setItem("sessionToken", session.token)
  return session
}

export async function checkSession() {
  const session = await GET<Session>("/check-session")
  return session
}

export async function logout() {
  return POST("/logout", {})
}
