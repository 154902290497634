import { Row, Col, Button } from "react-bootstrap"
import { FForm } from "../Components/Form/FormComponents"
import Bordered from "../Components/UIElements/Bordered"
import { useContext, useState } from "react"
import { StringsContext } from "../localization/translation"
import { Operation } from "../react-declarative-state/Operation"
import { Async } from "../react-declarative-state/Async"
import { requestPasswordReset } from "../services/user"
import { tap } from "rxjs"
import { toast } from "../portals/toast"

const ForgotPassword: React.FC<{}> = () => {
  const S = useContext(StringsContext)
  const [done, setDone] = useState(false)
  return (
    <Operation
      onDone={() => {
        toast(S.Password_change_link_sent, "success")
      }}
    >
      {(doOperation, progress) => {
        return (
          <FForm
            value={{ email: "" } as { email: string }}
            onSubmit={async ({ email }) => {
              doOperation(
                requestPasswordReset(email).pipe(
                  tap(
                    () => {
                      setDone(true)
                    },
                    () => {
                      toast(S.Sending_failed, "danger")
                    }
                  )
                )
              )
            }}
          >
            {({ FormGroupInput }, { formState }) => (
              <Row>
                <Col className="mt-5" sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
                  <Bordered className="pt-5 pb-4">
                    <div className="text-center mb-4">
                      <h4 className="mb-2">{S.Forgot_password}?</h4>
                      <p>{S.Forgot_password_info}</p>
                    </div>
                    <FormGroupInput title={S.Email} type="email" field="email" required />
                    <Button
                      disabled={done || formState.isSubmitting || progress === Async.Progress.Progressing}
                      variant={done ? "success" : "primary"}
                      className="mb-3"
                      type="submit"
                    >
                      {done ? S.Sent : S.Send}
                    </Button>
                  </Bordered>
                </Col>
              </Row>
            )}
          </FForm>
        )
      }}
    </Operation>
  )
}

export default ForgotPassword
