import { useContext, useState } from "react"
import SettingsPage from "../Components/PageWrappers/SettingsPage"
import { Constant } from "../react-declarative-state/Constant"
import { getUsersByCompanyId, inviteUsers } from "../services/user"
import { SessionContext } from "../types/Session"
import StupidProgressBar from "../Components/UIElements/ProgressBar"
import { StringsContext, Translation } from "../localization/translation"
import { Button, Card, FormControl, ListGroup, Modal } from "react-bootstrap"
import UserIcon from "../Components/UIElements/UserIcon"
import { Variable } from "../react-declarative-state/Variable"
import { Operation } from "../react-declarative-state/Operation"
import { toast } from "../portals/toast"
import { Async } from "../react-declarative-state/Async"
import { tap } from "rxjs"

const InviteUsersModal: React.FC<{ strings: Translation; onHide: () => void; companyId: string }> = ({
  strings: S,
  onHide,
  companyId,
}) => {
  const [usersToInvite, setUsersToInvite] = useState("")

  return (
    <Modal show onHide={onHide}>
      <Operation
        onDone={() => {
          toast(S.Invites_sent, "success")
          onHide()
        }}
      >
        {(doOperation, progress) => (
          <>
            <Modal.Header>
              <Modal.Title>{S.Invite_user}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-2">{S.Invite_user_info}</div>
              <FormControl
                as="textarea"
                rows={3}
                value={usersToInvite}
                onChange={(e) => {
                  setUsersToInvite(e.target.value)
                }}
              ></FormControl>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
              <Button variant="light" onClick={() => onHide()}>
                {S.Cancel}
              </Button>
              <Button
                disabled={progress === Async.Progress.Progressing}
                variant="primary"
                onClick={() => {
                  if (!usersToInvite) return
                  doOperation(
                    inviteUsers(companyId, usersToInvite).pipe(
                      tap(
                        () => {},
                        () => {
                          toast(S.Sending_failed, "danger")
                        }
                      )
                    )
                  )
                }}
              >
                {S.Send}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Operation>
    </Modal>
  )
}

const Users: React.FC<{}> = () => {
  const session = useContext(SessionContext)
  const S = useContext(StringsContext)
  return (
    <SettingsPage>
      <Constant
        value={getUsersByCompanyId(session.companyId)}
        placeholder={(progress) => <StupidProgressBar progress={progress} strings={S} />}
      >
        {(users) => (
          <Card>
            <Card.Header className="py-4 d-flex justify-content-between">
              <Card.Title className="m-0">{S.Users}</Card.Title>
              <Variable initialValue={false}>
                {(showModal, setShowModal) => (
                  <>
                    <Button variant="primary" onClick={() => setShowModal(true)}>
                      {S.Invite_user}
                    </Button>
                    {showModal && (
                      <InviteUsersModal
                        companyId={session.companyId}
                        strings={S}
                        onHide={() => {
                          setShowModal(false)
                        }}
                      />
                    )}
                  </>
                )}
              </Variable>
            </Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                {users.map((user) => (
                  <ListGroup.Item className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-start align-items-center">
                      <UserIcon initials={user.name[0]} />
                      <div className="d-flex flex-column ms-2">
                        <div>{user.name}</div>
                        <div className="text-muted small">{user.role === "companyAdmin" ? S.Admin : S.User}</div>
                      </div>
                    </div>
                    {session.role === "companyAdmin" && (
                      <div className="d-flex">
                        <Button variant="danger">{S.Delete}</Button>
                      </div>
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        )}
      </Constant>
    </SettingsPage>
  )
}

export default Users
