import React, { useContext } from "react"
import { StringsContext } from "../localization/translation"
import { SessionContext } from "../types/Session"
import { FForm } from "../Components/Form/FormComponents"
import { Button, Card } from "react-bootstrap"
import { Constant } from "../react-declarative-state/Constant"
import { editCompany, getCompanyById } from "../services/company"
import SettingsPage from "../Components/PageWrappers/SettingsPage"
import { Operation } from "../react-declarative-state/Operation"
import { Async } from "../react-declarative-state/Async"
import { tap } from "rxjs"
import { toast } from "../portals/toast"

const Profile: React.FC<{}> = () => {
  const S = useContext(StringsContext)
  const session = useContext(SessionContext)

  return (
    <SettingsPage>
      <>
        <Constant value={getCompanyById(session.companyId)}>
          {(company) => {
            return (
              <Card>
                <Card.Header className="py-4">
                  <Card.Title className="m-0">{S.Company}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Operation onDone={() => {}}>
                    {(doOperation, progress) => (
                      <FForm
                        value={company}
                        onSubmit={(updatedCompany) => {
                          const { id: __, ...toUpdate } = updatedCompany
                          doOperation(
                            editCompany(updatedCompany.id, toUpdate).pipe(
                              tap(
                                () => {
                                  toast(S.Company_save_success, "success")
                                },
                                () => {
                                  toast(S.Save_failed, "error")
                                }
                              )
                            )
                          )
                        }}
                      >
                        {({ FormGroupInput }) => (
                          <>
                            <FormGroupInput type="text" title={S.Name} field="name" required />
                            <FormGroupInput type="text" title={S.BusinessId} field="businessId" />
                            <FormGroupInput type="text" title={S.Address} field="address" />
                            <FormGroupInput type="text" title={S.City} field="city" />
                            <FormGroupInput type="text" title={S.PostalCode} field="postalCode" />
                            <div className="d-flex justify-content-end">
                              <Button disabled={progress === Async.Progress.Progressing} type="submit">
                                {S.Save}
                              </Button>
                            </div>
                          </>
                        )}
                      </FForm>
                    )}
                  </Operation>
                </Card.Body>
              </Card>
            )
          }}
        </Constant>
      </>
    </SettingsPage>
  )
}

export default Profile
