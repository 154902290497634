import { Navbar, Container, Nav, Button, Row, Col } from "react-bootstrap"
import { Navigate, useNavigate } from "react-router-dom"
import Session from "../types/Session"
import logo from "./../svg/forenel_logo_dark.svg"

const LandingPage: React.FC<{ session: Session | null }> = ({ session }) => {
  const navigate = useNavigate()

  if (session) return <Navigate to={"/app"} />

  return (
    <>
      <Navbar expand="md" className="bg-forenel-light" fixed="top">
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} alt="Forenel logo" height={30} />
          </Navbar.Brand>
          <Nav className="ms-auto d-none d-md-flex">
            <Nav.Link href="#features">Solutions</Nav.Link>
            <Nav.Link href="#features">Technology</Nav.Link>
            <Nav.Link href="#features">About us</Nav.Link>
          </Nav>
          <Navbar.Text className="ms-auto">
            <Button variant="outline-primary" className="rounded-pill px-3" onClick={() => navigate("login")}>
              Sign in
            </Button>
          </Navbar.Text>
        </Container>
      </Navbar>
      <Container fluid className="pt-5">
        <Row
          className="bg-forenel-dark"
          style={{
            height: "90vh",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: "url(/wind-mills.jpg)",
          }}
        >
          <Col md={12} className="pt-5 ps-4" style={{ zIndex: 1 }}>
            <Container>
              <div style={{ maxWidth: 600, color: "white" }} className="text-forenel-light mt-5">
                <h1 className="text-forenel-light mb-4">Empowering Energy insights for tomorrows markets</h1>
                <div className="mb-5">
                  Forenel delivers electricity price forecasts and market insights, empowering energy companies to
                  navigagte complex markets with confidence through advanced big data analysis and innovative technology
                  solutions
                </div>
                <div className="d-flex">
                  <Button className="me-2" size="lg">
                    Schedule call
                  </Button>
                  <Button size="lg" variant="outline-primary">
                    Learn more
                  </Button>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
        <div className="bg-forenel-light py-5">
          <Container>
            <div className="d-flex flex-column" style={{ maxWidth: 600 }}>
              <h5 className="text-forenel-blue mb-3">Forenel's Predictive Power</h5>
              <h1 className="mb-3">Smarter solutions for energy industry leaders</h1>
              <div>
                Leverage advanced analytics and technology to navigate the energy market complexities with confidence
                and driven growth
              </div>
            </div>
          </Container>
        </div>
      </Container>
    </>
  )
}

export default LandingPage
