import { isObservable, Observable } from "rxjs"

export function isAsync<T>(value: Observable<T> | T) {
  return isObservable(value)
}

export function createObservable<T>(value: Observable<T> | T): Observable<T> {
  if (isObservable(value)) return value as any
  else {
    throw Error("Passed operation is not an RxJS Observable or Promise")
  }
}
