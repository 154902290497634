import React, { useContext, useRef } from "react"
import _ from "lodash"
import { Alert, Badge, Button, Card } from "react-bootstrap"
import { ForecastType, Item } from "../../pages/Forecast/Forecast"
import { Controlled } from "../../react-declarative-state/Controlled"
import { deleteFromDashboardConfig, getForcast, upsertDashboardConfig } from "../../services/forecast"
import Chart from "chart.js/auto"
import { Line } from "react-chartjs-2"
import { CategoryScale } from "chart.js"
import moment from "moment"
import StupidProgressBar from "../UIElements/ProgressBar"
import { StringsContext } from "../../localization/translation"
import FAIcon from "../UIElements/FAIcon"
import DashboardConfig from "../../types/DashboardConfig"
import { Operation } from "../../react-declarative-state/Operation"
import { SessionContext } from "../../types/Session"
import { Async } from "../../react-declarative-state/Async"
import { Observable, of, switchMap } from "rxjs"
import zoomPlugin from "chartjs-plugin-zoom"
import HelpPopover from "../HelpPopover"

Chart.register(zoomPlugin)
Chart.register(CategoryScale)

const chartColors = {
  forenelBlue: "#0F71C5",
  forenelDark: "#11243e",
  lightBlue: "#92DCE5",
  red: "#FF6663",
  orange: "#F77F00",
  green: "#3BB273",
}

const PriceForecast: React.FC<{
  start: string
  end?: string
  item: Item
  type: ForecastType
  dashBoardConfig: DashboardConfig | null
  onDashboardConfigChanged: (dashBoardConfig: DashboardConfig) => void
  span?: 6 | 12
}> = (props) => {
  const S = useContext(StringsContext)
  const session = useContext(SessionContext)
  const chartRef = useRef<any>(null)

  return (
    <Card className="shadow-sm mb-3">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <div>
          <strong>{S.itemName(props.item)}</strong>{" "}
          <Badge className="small ms-2 py-1 px-2" bg="secondary">
            {_.capitalize(props.type.replaceAll("_", " "))}
          </Badge>
        </div>
        <Operation
          onDone={(config: DashboardConfig) => {
            console.log({ config })
            props.onDashboardConfigChanged(config)
          }}
        >
          {(doOperation, progress) => {
            const isInExistingConfig = props.dashBoardConfig?.config.items.some(
              (i) => i.item === props.item && i.type === props.type
            )
            return (
              <Button
                variant="light"
                disabled={progress === Async.Progress.Progressing}
                onClick={() => {
                  const currentConfig = props.dashBoardConfig
                  if (isInExistingConfig && currentConfig) {
                    doOperation(
                      deleteFromDashboardConfig(session.userId, session.companyId, {
                        item: props.item,
                        type: props.type,
                      }).pipe(
                        switchMap(() => {
                          return of({
                            ...props.dashBoardConfig,
                            config: {
                              ...currentConfig.config,
                              items: currentConfig.config.items.filter(
                                (i) => i.item !== props.item && i.type !== props.type
                              ),
                            },
                          }) as Observable<DashboardConfig>
                        })
                      )
                    )
                  } else {
                    doOperation(
                      upsertDashboardConfig(session.userId, session.companyId, {
                        item: props.item,
                        type: props.type,
                      })
                    )
                  }
                }}
                title={S.Add_to_front_page}
              >
                <FAIcon icon="star" className={isInExistingConfig ? "" : "fa-regular"} />
              </Button>
            )
          }}
        </Operation>
      </Card.Header>
      <Card.Body className="pe-3">
        <Controlled
          value={getForcast(_.pick(props, ["start", "end", "item", "type"]))}
          placeholder={(progress) => <StupidProgressBar strings={S} progress={progress} />}
        >
          {(forecast) => {
            if (!forecast.data) {
              return (
                <Alert variant="danger">
                  <div className="d-flex flex-column">
                    <div className="mb-2 text-strong">{S.Something_went_wrong_title}</div>
                    <div>{S.Something_went_wrong_text}</div>
                  </div>
                </Alert>
              )
            }

            const sharedSettings = {
              borderWidth: 0.5,
              pointRadius: 1,
              pointHitRadius: 10,
            }

            const labels = forecast.data.dt_utc.map((utc) => moment(utc).format("D.M HH:mm"))

            const datasets: any[] = []

            if ("f" in forecast.data) {
              datasets.push({
                label: S.Forecast,
                data: forecast.data.f,
                borderColor: chartColors.forenelBlue,
                backgroundColor: chartColors.forenelBlue,
                ...sharedSettings,
                borderWidth: 3,
              })
            }

            if ("min" in forecast.data) {
              datasets.push({
                label: `${S.Forecast} min`,
                data: forecast.data.min,
                borderColor: chartColors.lightBlue,
                backgroundColor: "rgba(146, 220, 229, 0.2)",
                fill: "2",
                order: 100,
                ...sharedSettings,
              })
            }

            if ("max" in forecast.data) {
              datasets.push({
                label: `${S.Forecast} max`,
                data: forecast.data.max,
                borderColor: chartColors.lightBlue,
                backgroundColor: "rgba(146, 220, 229, 0.2)",
                order: 101,
                ...sharedSettings,
              })
            }

            if ("a" in forecast.data) {
              datasets.push({
                label: S.Actual,
                data: forecast.data.a,
                borderColor: chartColors.orange,
                backgroundColor: chartColors.orange,
                ...sharedSettings,
                borderWidth: 2,
              })
            }

            const levelKeys = Object.keys(forecast.data).filter((key) => key.includes("level"))

            for (const levelKey of levelKeys) {
              const label =
                forecast.meta.forecast_descriptions.find((meta) => meta.name === levelKey)?.description || null
              datasets.push({
                label: label || levelKey,
                data: forecast.data[levelKey],
                ...sharedSettings,
                borderWidth: 1,
                borderColor: chartColors.lightBlue,
                backgroundColor: chartColors.lightBlue,
              })
            }

            return (
              <>
                <div className="d-flex justify-content-between mb-2">
                  {forecast.meta.unit}
                  <HelpPopover>
                    <div className="mb-1">{S.ChartHelper1}</div>
                    <div>{S.ChartHelper2}</div>
                  </HelpPopover>
                </div>
                <div>
                  <Line
                    ref={chartRef}
                    style={{ maxHeight: 600 }}
                    onDoubleClick={() => {
                      chartRef.current?.resetZoom()
                    }}
                    options={{
                      responsive: true,
                      plugins: {
                        zoom: {
                          pan: {
                            enabled: true,
                            mode: "xy",
                            modifierKey: "alt",
                          },
                          zoom: {
                            mode: "xy",
                            drag: {
                              enabled: true,
                              borderColor: chartColors.forenelBlue,
                              borderWidth: 1,
                              backgroundColor: "rgba(146, 220, 229, 0.3)",
                            },
                          },
                        },
                        tooltip: {
                          mode: "index",
                          intersect: true,
                          backgroundColor: chartColors.forenelDark,
                          padding: 16,
                          cornerRadius: 10,
                        },
                        legend: {
                          position: "bottom",
                          labels: {
                            usePointStyle: true,
                            generateLabels: function () {
                              return datasets.map(({ label, borderColor }, i) => {
                                return {
                                  pointStyle: "rectRounded",
                                  text: label,
                                  fillStyle: borderColor,
                                  strokeStyle: borderColor,
                                  color: "white",
                                  index: i,
                                }
                              })
                            },
                          },
                        },
                      },
                      hover: {
                        mode: "x",
                        intersect: true,
                      },
                      scales: {
                        x: {
                          ticks: { autoSkip: true, maxTicksLimit: props.span === 6 ? 3 : 5 },
                        },
                        y: {
                          ticks: {
                            callback: (value) => (typeof value === "number" ? _.round(value) : value),
                          },
                        },
                      },
                    }}
                    data={{
                      datasets,
                      labels,
                    }}
                  />
                  <div style={{ position: "absolute", top: 10, right: 10 }}></div>
                </div>
              </>
            )
          }}
        </Controlled>
      </Card.Body>
    </Card>
  )
}

export default PriceForecast
